import { defineMessages } from 'react-intl';

export const formMessages = defineMessages({
    'exemption.title': {
        id: 'forms.exemption.title',
        defaultMessage: 'Informations personnelles',
    },
    'exemption.signature.phone.description': {
        id: 'forms.exemption.signature.phone.description',
        defaultMessage:
            'Un téléphone mobile est indispensable pour pouvoir signer vos documents en ligne.',
    },
    'exemption.signature.phone.title': {
        id: 'widgets.exemption.signature.phone.title',
        defaultMessage: 'Numéro de téléphone',
    },
    'common.employee-number.title': {
        id: 'widgets.common.employee-number.title',
        defaultMessage: 'Matricule paie',
    },
    'common.employee-number.description': {
        id: 'widgets.common.employee-number.description',
        defaultMessage:
            'Retrouvez votre matricule paie sur votre bulletin de salaire.',
    },
    'date.coverage-end-date.title': {
        id: 'widgets.date.coverage-end-date.title',
        defaultMessage: 'Date de fin de couverture',
    },
    'date.coverage-end-date.css-description': {
        id: 'widgets.date.coverage-end-date.css-description',
        defaultMessage:
            "Cette information apparaît sur votre Attestation de droit à l'assurance maladie et à la complémentaire santé solidaire, que vous pouvez télécharger sur le site Ameli.fr.",
    },
    'attachments.css-certificate.title': {
        id: 'widgets.attachments.css-certificate.title',
        defaultMessage:
            "Attestation de droit à l'assurance maladie et à la complémentaire santé solidaire",
    },
    'attachments.css-certificate.description': {
        id: 'widgets.attachments.css-certificate.description',
        defaultMessage:
            "Vous pouvez télécharger votre attestation de droit à l'assurance maladie et à la complémentaire santé solidaire sur le site Ameli.fr ou auprès de votre organisme de sécurité sociale si vous dépendez d'un régime spécial.",
    },
    'exemption.insurer.title': {
        id: 'widgets.exemption.insurer.title',
        defaultMessage: "Nom de l'assureur",
    },
    'exemption.insurer.description': {
        id: 'widgets.exemption.insurer.description',
        defaultMessage:
            "Indiquez le nom de l'organisme assureur figurant sur le contrat d'assurance.",
    },
    'date.coverage-end-date.description': {
        id: 'widgets.date.coverage-end-date.description',
        defaultMessage:
            '<p>Si vous bénéficiez d’une couverture individuelle frais de santé, veuillez indiquer la date du prochain renouvellement de votre contrat.</p><p>Pour tous les autres motifs, veuillez indiquer la date de fin de couverture présente sur votre carte de tiers payant.</p>',
    },
    'date.coverage-end-date.help': {
        id: 'widgets.date.coverage-end-date.help',
        defaultMessage:
            'Exemple pour le motif « je bénéficie d’une couverture individuelle frais de santé » : j’ai souscrit à un contrat individuel le 1er août 2024, alors j’indique une date de fin de couverture au 31 juillet 2025',
    },
    'attachments.declaration-of-honour.title': {
        id: 'widgets.attachments.declaration-of-honour.title',
        defaultMessage: 'Attestation manuscrite sur l’honneur',
    },
    'attachments.declaration-of-honour.description': {
        id: 'widgets.attachments.declaration-of-honour.description',
        defaultMessage:
            "<p>Votre attestation manuscrite sur l'honneur doit contenir les éléments suivants : </p><p>Je soussigné(e) [<i>Prénom, Nom</i>] né(e) le [<i>Date de naissance</i>] à [<i>lieu de naissance</i>] et domicilié(e) [<i>Adresse postale</i>] déclare sur l'honneur : </p><p>•Être dans l'une des situations me permettant d'être dispensé(e) de l'affiliation audit régime frais de santé</p><p>•Demander à ne pas cotiser au régime frais de santé mis en place de manière collective et obligatoire et renoncer également à la part de cotisations prise en charge par l'employeur</p><p>•Avoir été informé(e) par mon employeur, préalablement à la mise en œuvre de la dispense, des conséquences de mon choix</p><p>•Sincères et exacts les éléments communiqués ci-dessus. </p><p><i>A noter : </i></p><p><i>•Article L.441-1 du code pénal : « Constitue un faux toute altération frauduleuse de la vérité, de nature à causer un préjudice et accomplie par quelque moyen que ce soit, dans un écrit ou tout autre support d'expression de la pensée qui a pour objet ou qui peut avoir pour effet d'établir la preuve d'un droit ou d'un fait ayant des conséquences juridiques. Le faux et l'usage de faux sont punis de trois ans d'emprisonnement et de 45 000 euros d'amende ».</i></p><p><i>•Des pièces justificatives pourront être demandées en complément de votre attestation</i></p>",
    },
    'one-file-upload.title': {
        id: 'widgets.one-file-upload.title',
        defaultMessage: 'Document à joindre',
    },
    'attachments.insurer-certificate.title': {
        id: 'widgets.attachments.insurer-certificate.title',
        defaultMessage: "Attestation de couverture de l'assureur",
    },
    'attachments.insurer-certificate.description': {
        id: 'widgets.attachments.insurer-certificate.description',
        defaultMessage:
            "L'attestation doit mentionner le caractère individuel de votre couverture.",
    },
    'attachments.employer-certificate.title': {
        id: 'widgets.attachments.employer-certificate.title',
        defaultMessage: "Attestation de l'employeur qui vous couvre",
    },
    'attachments.coverage-certificate.title': {
        id: 'widgets.attachments.coverage-certificate.title',
        defaultMessage: 'Attestation justificative',
    },
    'attachments.coverage-certificate.help': {
        id: 'widgets.attachments.coverage-certificate.help',
        defaultMessage:
            "<l>· <b>Régime complémentaire collectif et obligatoire d'entreprise</b> : Justificatif de l'employeur de votre conjoint(e) ou parent attestant de la couverture  obligatoire des ayants-droit (taux unique famille ou cotisation en fonction de la situation réelle de famille)</l><l></l><l>· <b>Régime d'Alsace Moselle</b> : Attestation AMELI de couverture par le régime Alsace Moselle</l><l></l><l>· <b>Régime Camieg</b> : Attestation fourni par la CAMIEG</l><l></l><l>· <b>Régime des fonctionnaires d'Etat et territoriaux</b> : Attestation de l'organisme assureur mentionnant l'appartenance à un des dispositifs prévus par le décret 19 septembre 2007 ou du décret du 8 novembre 2011</l>",
    },
    'date.contract-end-date.title': {
        id: 'widgets.date.contract-end-date.title',
        defaultMessage: 'Date de fin de votre CDD',
    },
    'attachments.coverage-certificate.other-regime-help': {
        id: 'widgets.attachments.coverage-certificate.other-regime-help',
        defaultMessage:
            "<l>· <b>Régime complémentaire collectif et obligatoire d'entreprise</b> : Justificatif de l'employeur de votre conjoint(e) ou parent attestant de la couverture  obligatoire des ayants-droit (taux unique famille ou cotisation en fonction de la situation réelle de famille)</l><l></l><l>· <b>Régime d'Alsace Moselle</b> : Attestation AMELI de couverture par le régime Alsace Moselle</l><l></l><l>· <b>Régime Camieg</b> : Attestation fourni par la CAMIEG</l><l></l><l>· <b>Régime des fonctionnaires d'Etat et territoriaux</b> : Attestation de l'organisme assureur mentionnant l'appartenance à un des dispositifs prévus par le décret 19 septembre 2007 ou du décret du 8 novembre 2011</l><l></l><l>· <b>Régime des fonctionnaires d'Etat et territoriaux</b> : Attestation de l'organisme assureur mentionnant l'appartenance à un des dispositifs prévus par le décret 19 septembre 2007 ou du décret du 8 novembre 2011</l><l></l><l>· <b>Caisse du personnel de la SCNF ou Régime de la Sécurité sociale des gens de la mer</b> : Attestation de la SCNF ou de la sécurité sociale des gens de mer</l>",
    },
    'beneficiaries-designation.consent.title': {
        id: 'forms.beneficiaries-designation.consent.title',
        defaultMessage: 'En signant ce document',
    },
    'informations-accuracy.description': {
        id: 'forms.informations-accuracy.description',
        defaultMessage:
            'Je déclare toutes les informations fournies exactes et sincères.',
    },
    'contract-check.description': {
        id: 'forms.contract-check.description',
        defaultMessage:
            "Je reconnais avoir reçu et pris connaissance de la Notice d'information de mon contrat et de ses annexes.",
    },
    'form-consent.description': {
        id: 'forms.form-consent.description',
        defaultMessage:
            'Je consens à la signature de ma désignation de bénéficiaires.',
    },
    'revert-to-standard.description': {
        id: 'forms.revert-to-standard.description',
        defaultMessage:
            "J'avais précédemment rempli une clause spécifique et je souhaite la modifier pour revenir sur la clause standard du contrat.",
    },
    'identity-check.title': {
        id: 'forms.identity-check.title',
        defaultMessage: "Vérification de votre pièce d'identité ",
    },
    'beneficiary-designation.identity-check.description': {
        id: 'widgets.beneficiary-designation.identity-check.description',
        defaultMessage:
            "Votre pièce d'identité peut-être une Carte Nationale d'Identité ou un passeport en cours de validité.",
    },
    'beneficiary-designation.identity-document.title': {
        id: 'widgets.beneficiary-designation.identity-document.title',
        defaultMessage: "Ajouter votre pièce d'identité",
    },
    'beneficiary-designation.identity-document.description': {
        id: 'widgets.beneficiary-designation.identity-document.description',
        defaultMessage:
            "Ce document doit contenir l'entièreté du <b>recto</b> et du <b>verso</b> de votre pièce d'identité, en <b>couleur</b>. Il doit être parfaitement lisible.",
    },
    'signature.identity.send-code.label': {
        id: 'forms.signature.identity.send-code.label',
        defaultMessage: 'Vérifier mon identité et recevoir mon code',
    },
    'beneficiaries-designation.parents': {
        id: 'widgets.beneficiaries-designation.parents',
        defaultMessage: 'Mes parents',
    },
    'common.beneficiary-maiden-name.title': {
        id: 'widgets.common.beneficiary-maiden-name.title',
        defaultMessage: 'Nom de jeune fille si échéant',
    },
    'common.beneficiary-birth-city.title': {
        id: 'widgets.common.beneficiary-birth-city.title',
        defaultMessage: 'Commune de naissance',
    },
    'common.beneficiary-birth-country.title': {
        id: 'widgets.common.beneficiary-birth-country.title',
        defaultMessage: 'Pays de naissance',
    },
    'address.residence-address.title': {
        id: 'widgets.address.residence-address.title',
        defaultMessage: 'Adresse de résidence',
    },
    'address.residence-address.description': {
        id: 'widgets.address.residence-address.description',
        defaultMessage: 'Voie, code postal, commune',
    },
    'user-infos.birth-last-name.title': {
        id: 'widgets.user-infos.birth-last-name.title',
        defaultMessage: 'Nom de naissance',
    },
    'user-infos.birth-last-name.identity-check-description': {
        id: 'widgets.user-infos.birth-last-name.identity-check-description',
        defaultMessage:
            "Veuillez saisir votre nom de naissance à l'identique de votre pièce d'identité",
    },
    'user-infos.usual-last-name.title': {
        id: 'widgets.user-infos.usual-last-name.title',
        defaultMessage: "Nom d'usage",
    },
    'user-infos.usual-last-name.identity-check-description': {
        id: 'widgets.user-infos.usual-last-name.identity-check-description',
        defaultMessage:
            "Veuillez saisir votre nom d'usage à l'identique de votre pièce d'identité",
    },
    'user-infos.first-name.identity-check-description': {
        id: 'widgets.user-infos.first-name.identity-check-description',
        defaultMessage:
            "Veuillez saisir votre prénom à l'identique de votre pièce d'identité",
    },
    'user-infos.second-first-name.identity-check-description': {
        id: 'widgets.user-infos.second-first-name.identity-check-description',
        defaultMessage:
            "Veuillez saisir votre deuxième prénom à l'identique de votre pièce d'identité",
    },
    'user-infos.second-first-name.title': {
        id: 'widgets.user-infos.second-first-name.title',
        defaultMessage: 'Deuxième prénom',
    },

    'user-infos.third-first-name.title': {
        id: 'widgets.user-infos.third-first-name.title',
        defaultMessage: 'Troisième prénom',
    },
    'user-infos.third-first-name.identity-check-description': {
        id: 'widgets.user-infos.third-first-name.identity-check-description',
        defaultMessage:
            "Veuillez saisir votre troisième prénom à l'identique de votre pièce d'identité",
    },
    'user-infos.fourth-first-name.title': {
        id: 'widgets.user-infos.fourth-first-name.title',
        defaultMessage: 'Quatrième prénom',
    },
    'user-infos.fourth-first-name.identity-check-description': {
        id: 'widgets.user-infos.fourth-first-name.identity-check-description',
        defaultMessage:
            "Veuillez saisir votre quatrième prénom à l'identique de votre pièce d'identité",
    },
    'common.birth-city.title': {
        id: 'widgets.common.birth-city.title',
        defaultMessage: 'Commune de naissance',
    },
    'common.birth-country.title': {
        id: 'widgets.common.birth-country.title',
        defaultMessage: 'Pays de naissance',
    },
    'residency-address.street-name.title': {
        id: 'widgets.residency-address.street-name.title',
        defaultMessage: 'Adresse de résidence',
    },
    'residency-address.postal-code.title': {
        id: 'widgets.residency-address.postal-code.title',
        defaultMessage: 'Code postal de résidence',
    },
    'residency-address.city.title': {
        id: 'widgets.residency-address.city.title',
        defaultMessage: 'Commune de résidence',
    },
    'common.socialSecurityNumber.placeholder': {
        id: 'widgets.common.socialSecurityNumber.placeholder',
        defaultMessage: '0 00 00 00 000 000 00',
    },
    'beneficiary-designation.signature.phone.title': {
        id: 'widgets.beneficiary-designation.signature.phone.title',
        defaultMessage: 'Numéro de téléphone portable',
    },
    'beneficiary-designation.signature.phone.description': {
        id: 'widgets.beneficiary-designation.signature.phone.description',
        defaultMessage:
            'Saisissez le numéro de téléphone sur lequel vous souhaitez recevoir votre code de vérification pour la signature des documents.',
    },
    'common.email.placeholder': {
        id: 'widgets.common.email.placeholder',
        defaultMessage: 'exemple@mail.fr',
    },
    'identity-check.type.card': {
        id: 'widgets.identity-check.type.card',
        defaultMessage: 'Carte d’identité',
    },
    'identity-check.type.passport': {
        id: 'widgets.identity-check.type.passport',
        defaultMessage: 'Passeport',
    },
    'identity-document.card-attachment.title': {
        id: 'widgets.identity-document.card-attachment.title',
        defaultMessage:
            "Ajouter le recto et le verso de votre Carte Nationale d'Identité",
    },
    'identity-document.card-attachment.description': {
        id: 'widgets.identity-document.card-attachment.description',
        defaultMessage: 'Sur un seul document ou sur deux documents séparés.',
    },
    'identity-document.passport.title': {
        id: 'widgets.identity-document.passport.title',
        defaultMessage: 'Ajouter votre passeport',
    },
    'identity-check.type.title': {
        id: 'widgets.identity-check.type.title',
        defaultMessage: "Sélectionner votre pièce d'identité",
    },
    'identity-check.passport-confirmation.description': {
        id: 'widgets.identity-check.passport-confirmation.description',
        defaultMessage: 'Je confirme avoir ajouté l’entièreté du document',
    },
    'identity-check.idcard-confirmation.description': {
        id: 'widgets.identity-check.idcard-confirmation.description',
        defaultMessage:
            'Je confirme avoir ajouté l’entièreté du document (recto et verso).',
    },
});
